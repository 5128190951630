import { Box, Flex, Skeleton, Stack, VStack } from "@chakra-ui/react";
import { Card } from "@doorstead/components/Card";
import { GrayText } from "@doorstead/components/GrayText";
import { OutlineButton, PrimaryButton } from "@doorstead/components/buttons";
import { YesNoField } from "@doorstead/components/fields/YesNoField";
import { useIsMobile } from "@doorstead/components/hooks/useIsMobile";
import { CrossIcon } from "@doorstead/components/icons/CrossIcon";
import { isNull } from "lodash";
import React, { Ref, useState } from "react";
import { useApplicantAnalytics } from "../../../../../hooks/applicants/useApplicantAnalytics";
import { useIsApplicantEditable } from "../../../../../hooks/applicants/useIsApplicantEditable";
import { useIsAppMutating } from "../../../../../hooks/useIsAppMutating";
import { CardTitle } from "../../../CardTitle";
import { Props } from "./typings";

export const ValuesCard = React.forwardRef(
  <T extends unknown>(
    {
      title,
      subTitle,
      noLabel,
      yesLabel,
      numberLabel,
      addLabel,
      width,
      padding,
      values,
      onAdd,
      onReset,
      onContinue,
      onRemove,
      content,
      eventName,
      hasAddMoreButton = true,
      idField,
      isLoading,
      ...stackProps
    }: Props<T>,
    ref: Ref<any>
  ) => {
    const isMutating = useIsAppMutating();
    const applicantAnalytics = useApplicantAnalytics();
    const disabled = !useIsApplicantEditable();
    const isMobile = useIsMobile();

    const [isEmployed, setIsEmployed] = useState<boolean | null>(null);

    return (
      <Card {...stackProps} padding={padding} width={width} ref={ref}>
        <VStack spacing={4} width="100%" alignItems="flex-start">
          <CardTitle>{title}</CardTitle>
          <GrayText>{subTitle}</GrayText>
          {values.length <= 1 && (
            <Flex width="100%">
              <YesNoField
                noLabel={noLabel}
                yesLabel={yesLabel}
                disabled={disabled}
                value={isNull(isEmployed) ? values.length >= 1 || null : values.length >= 1}
                onChange={(value) => {
                  if (!isMutating) {
                    if (value) {
                      if (values.length === 0) {
                        applicantAnalytics?.track(`TP_${eventName}_ADDED`);
                        onAdd();
                        setIsEmployed(true);
                      }
                    } else {
                      applicantAnalytics?.track(`TP_${eventName}_RESET`);
                      onReset();
                      setIsEmployed(false);
                    }
                  }
                }}
              />
            </Flex>
          )}
          {isLoading && (
            <VStack width="full" gap={2}>
              <Skeleton height="10" width="100%" borderRadius="6px" />
              <Skeleton height="10" width="100%" borderRadius="6px" />
            </VStack>
          )}
          {!isLoading &&
            values.map((value, index) => {
              return (
                <VStack width="100%" key={value[idField] as string} spacing={2} alignItems="flex-start">
                  {values.length >= 2 && (
                    <Flex width="100%" alignItems="center" justifyContent="space-between">
                      <Box color="gray.800" fontFamily="Inter" fontSize={20} fontWeight={600}>
                        {numberLabel} #{index + 1}
                      </Box>
                      {!disabled && (
                        <CrossIcon
                          size={15}
                          onClick={() => {
                            if (!isMutating) {
                              applicantAnalytics?.track(`TP_${eventName}_REMOVED`, { index });
                              onRemove(index, value);
                            }
                          }}
                        />
                      )}
                    </Flex>
                  )}
                  {content(index, value)}
                </VStack>
              );
            })}
          <Stack direction="row" spacing={0} gap={4} flexWrap="wrap" w="100%">
            <PrimaryButton isFullWidth={isMobile} onClick={onContinue}>
              Continue
            </PrimaryButton>
            {values.length >= 1 && hasAddMoreButton && (
              <OutlineButton
                disabled={disabled}
                isFullWidth={isMobile}
                isLoading={isMutating}
                onClick={() => {
                  if (!isMutating) {
                    applicantAnalytics?.track(`TP_${eventName}_ADDED`);
                    onAdd();
                  }
                }}
              >
                {addLabel}
              </OutlineButton>
            )}
          </Stack>
        </VStack>
      </Card>
    );
  }
);
